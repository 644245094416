import './answerOption.css'
import parse from 'html-react-parser';
const PreviewAnswerOption = (props)=>{
    console.log(props,"props here");
    const {isAnswer, on, oc, wrong} = props
    let cn = 'answer_option '+ (isAnswer ? 'answer' : wrong ? 'wrong':"");

    return(
        <div className={cn}>
        <div className='option_num'><div style={{alignSelf: 'center'}}>{on}</div></div>
        <div className='option_content'>{parse(oc)}</div>
        </div>
    )
}
export default PreviewAnswerOption