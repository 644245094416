import notifyIcon from "../../assets/images/notify_icon.png";
import profileIcon from "../../assets/images/profile_icon.png";
import settingsIcon from "../../assets/images/settings_icon.png";
import RatulMenuIcon from "../widgets/ratulMenuIcon";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CustomCircularP from "../widgets/customCircularP";
import { API_ENDPOINT } from "../../constants/constants";
import { getHeader } from "../../utilities/utility";
import axios from "axios";
import {
  Divider,
  IconButton,
  Badge,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LockResetIcon from "@mui/icons-material/LockReset";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./header.css";
import { deleteToken } from "../../utilities/utility";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const Header = (props) => {
  const { collapse, setCollapse, title } = props;
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [popupData, setPopupData] = useState(null); // State for the popup
  useEffect(() => {
    loadNotifications();
  }, []);
  //Load notifications
  function loadNotifications() {
    setLoading(true);
    axios
      .get(API_ENDPOINT + "trainer/notifications", getHeader())
      .then((res) => {
        setLoading(false);
        let d = res.data;
        console.log(d);
        if (d.success) {
          setNotifications(d.message);
        }
      });
  }
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setPopupData(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  // Mark all notifications as read
  const markAllAsRead = () => {
    setNotifications(notifications.map((notif) => ({ ...notif, read: true })));
  };
  // Open popup with notification details
  const handleNotificationClick = (notification) => {
    setPopupData(notification);
  };
  let temp = title.replaceAll("-", " ");
  const mytitle = capitalize(temp);

  const [anchorEl, setAnchorEl] = useState(null);

  const accountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closePopup = () => {
    setPopupData(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function capitalize(str) {
    const words = str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
    return words;
  }
  const goToReset = () => {
    document.location.href = "/generate-reset-link";
  };
  const goToProfile = () => {
    document.location.href = "/trainer/trainer-profile";
  };
  const logout = () => {
    deleteToken();
    document.location.href = "/login";
  };
  return (
    <div
      className="dashboard_header"
      style={{ paddingLeft: collapse ? 90 : 248 }}
    >
      <CustomCircularP show={loading} />
      <RatulMenuIcon collapse={collapse} setCollapse={setCollapse} />
      <div
        style={{
          flex: 1,
          paddingLeft: 12,
          alignSelf: "center",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {mytitle}
      </div>
      <div
        style={{
          alignSelf: "center",
          marginRight: "8px",
          position: "relative",
        }}
      >
        <IconButton onClick={() => setShowNotifications(!showNotifications)}>
          <NotificationsNoneIcon sx={{ color: "#777", fontSize: "30px" }} />
          <Badge badgeContent={notifications.length} color="error"></Badge>
        </IconButton>
        <div
          ref={dropdownRef}
          className={
            showNotifications ? "notification-tray" : "notification-tray hide"
          }
        >
          <div style={{ color: "#777", textAlign: "center" }}>
            <div className="dropdown-header" style={dropdownHeaderStyles}>
              <h4 className="mb-0">Notifications</h4>
              <IconButton
                onClick={() => setShowNotifications(false)}
                className="close-btn-icon"
                aria-label="delete"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
            {/* <button
              className="mt-2"
              onClick={markAllAsRead}
              style={markReadButtonStyles}
            >
              Mark all as read
            </button> */}
            <ul style={listStyles}>
              {notifications.length > 0 ? (
                notifications.map((notif) => (
                  <li
                    key={notif.id}
                    style={notif.read ? readStyles : unreadStyles}
                    onClick={() => handleNotificationClick(notif)}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <NotificationsIcon />
                      <h6 className="mb-0">{notif.title}</h6>
                    </div>
                    <p className="mb-0 ms-1" style={{ textAlign: "left" }}>
                      {notif.message}
                    </p>
                  </li>
                ))
              ) : (
                <li>No notifications</li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div
        style={{
          alignSelf: "center",
          borderRadius: "50%",
          marginRight: "16px",
        }}
      >
        <IconButton
          aria-describedby={id}
          onClick={accountClick}
          aria-label="account"
          size="large"
        >
          <ManageAccountsIcon />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuList>
            <MenuItem onClick={goToProfile}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={goToReset}>
              <ListItemIcon>
                <LockResetIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Reset Password</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </div>

      {/* <img style={{width: 48, height: 48, alignSelf: 'center', marginLeft: 20, marginRight: 20, cursor: 'pointer'}} src={profileIcon} alt=''/> */}
      {/* Popup */}
      {popupData && (
        <div ref={modalRef} className="popup" style={popupStyles}>
          <div className="popup-content" style={popupContentStyles}>
            <h3>{popupData.title}</h3>
            <p>{popupData.message}</p>
            {/* <button onClick={closePopup} style={closeButtonStyles}>
              Close
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};
// Styles

const dropdownHeaderStyles = {
  padding: "10px",
  borderBottom: "1px solid #eee",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const markReadButtonStyles = {
  border: "none",
  background: "transparent",
  cursor: "pointer",
  color: "#007bff",
};

const listStyles = {
  listStyleType: "none",
  margin: 0,
  padding: "10px",
  height: "350px",
  overflowY: "scroll",
};

const unreadStyles = {
  background: "#f9f9f9",
  padding: "10px",
  borderBottom: "1px solid #eee",
};

const readStyles = {
  background: "#fff",
  padding: "10px",
  borderBottom: "1px solid #eee",
  color: "#aaa",
};
const popupStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  borderRadius: "8px",
  zIndex: 2000,
  minWidth: "300px",
  overflowY: "scroll",
};

const popupContentStyles = {
  padding: "20px",
  textAlign: "center",
};

const closeButtonStyles = {
  marginTop: "20px",
  padding: "10px 20px",
  border: "none",
  background: "#007bff",
  color: "#fff",
  borderRadius: "4px",
  cursor: "pointer",
};
export default Header;
