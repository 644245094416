import React, { useState } from 'react';
import './planWidget.css'
import {Button} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MyModal from '../widgets/myModal/myModal';
import ContactForPlan from './ContactForPlan';
const ContactUs = ()=>{
    const [showModal, setShowModal] = useState(false)
    
    const onContactUs = ()=>{
        setShowModal(true)
    }
    const onContactUsSuccess = ()=>{
        setShowModal(false)
    }
    return (
        <>
            <MyModal  mWidth="640px" showModal={showModal} setShowModal={setShowModal} modalC={<ContactForPlan onContactUs={onContactUsSuccess} />}/>
           
            
                <Button onClick={onContactUs} variant="outlined" endIcon={<ArrowForwardIcon/>}>Contact Us</Button>
            
        </>
    )
} 
export default ContactUs