import { Button, IconButton, Box } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RatulButton from "./ratulButton";
import { HOSTNAME } from "../../constants/constants";
import { toast } from 'react-toastify';

const AssessmentCard = (props) => {
  const { name, num, duration, code, linkCode, show } = props;
  const al = HOSTNAME + 'assessment/' + linkCode;

  const startAssessment = () => {
    document.location.href = "/trainer/live-assessment/" + linkCode;
  };
  
  const copyCode = () => {
    navigator.clipboard.writeText(code);
    toast("Copied Assessment Code!");
  };
  
  const copyLink = () => {
    navigator.clipboard.writeText(al);
    toast("Copied Assessment Link!");
  };

  return (
    <Box 
      className="assessment_card" 
      sx={{
        display: show ? 'flex' : 'none',
        flexDirection: { xs: 'column', sm: 'row' },
        paddingRight: 2,
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 2,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      <Box className="assessment_card_item" sx={{ flex: 1, marginBottom: { xs: 2, sm: 0 } }}>
        <div className="item_header">Assessment Name</div>
        <div style={{ marginTop: 8 }}>{name}</div>
      </Box>
      
      <Box className="assessment_card_item" sx={{ flex: 1, marginBottom: { xs: 2, sm: 0 } }}>
        <div className="item_header">No of Questions</div>
        <div style={{ marginTop: 8 }}>{num}</div>
      </Box>

      <Box className="assessment_card_item" sx={{ flex: 1, marginBottom: { xs: 2, sm: 0 } }}>
        <div className="item_header">Duration</div>
        <div style={{ marginTop: 8 }}>{duration} minutes</div>
      </Box>

      <Box className="assessment_card_item" sx={{ flex: 1, marginBottom: { xs: 2, sm: 0 }} }>
        <div className="item_header">Code</div>
        <div style={{ display: 'flex', width: '100%', color: '#444', marginTop: 8 }}>
          <div style={{ alignSelf: 'center', fontSize: 15 }}>{code}</div>
          <div style={{ cursor: 'pointer', marginLeft: 4 }}>
            <IconButton onClick={copyCode} color="secondary" aria-label="Copy">
              <ContentCopyIcon sx={{ width: 18, height: 18 }} />
            </IconButton>
          </div>
        </div>
      </Box>

      <Box className="assessment_card_item" sx={{ flex: 1 }}>
        <Button onClick={copyLink} startIcon={<InsertLinkIcon />} size="small" variant="outlined">
          Copy Link
        </Button>
      </Box>

      <Box sx={{ alignSelf: 'center', textAlign: 'center', flex: 1, marginTop: { xs: 2, sm: 0 } }}>
        <RatulButton text="Go Live" onClick={startAssessment} />
      </Box>
    </Box>
  );
}

export default AssessmentCard;
