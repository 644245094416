const DashCard = ({ bgColor, title, value, subvalue, progress, progressColor }) => (
    <div
      style={{
        backgroundColor: bgColor,
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'row', // Inline layout
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '120px', // Set consistent height
        borderBottom: `4px solid ${progressColor || 'transparent'}`, // Add bottom border
      }}
    >
      <div style={{ flex: 1 }}>
        {/* Title */}
        <h4 style={{ margin: '0 0 8px', fontSize: '14px', color: '#333', textTransform: 'uppercase' }}>
          {title}
        </h4>
        {/* Value */}
        <h2 style={{ margin: '0 0 4px', fontSize: '24px', fontWeight: 'bold' }}>
          {value}
        </h2>
        {/* Subvalue */}
        <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>{subvalue}</p>
      </div>
  
      {progress !== undefined && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Circular Progress */}
          <svg
            viewBox="0 0 36 36"
            style={{
              width: '50px',
              height: '50px',
            }}
          >
            {/* Background Circle */}
            <circle
              cx="18"
              cy="18"
              r="15"
              fill="none"
              stroke="#e0e0e0"
              strokeWidth="3"
            />
            {/* Progress Circle */}
            <circle
              cx="18"
              cy="18"
              r="15"
              fill="none"
              stroke={progressColor}
              strokeWidth="3"
              strokeDasharray={`${progress}, 100`}
              transform="rotate(-90 18 18)"
            />
          </svg>
          <span
            style={{
              position: 'absolute',
              fontSize: '12px',
              color: '#333',
              fontWeight: 'bold',
            }}
          >
            {progress}%
          </span>
        </div>
      )}
    </div>
  );
  

export default DashCard;
