import axios from "axios";
import { useEffect, useState } from "react";
import al from "../../assets/images/al.gif";
import { io } from "socket.io-client";
import { API_ENDPOINT, HEADER_TOKEN } from "../../constants/constants";
import userEvent from "@testing-library/user-event";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";

import Live from "./live/live";
import "./assessment.css";
import gl from "../../assets/gifs/waitingAnim.gif";
import wft from "../../assets/images/wft.jpg";
import IntroHeader from "../widgets/introHeader";
import { getCandidateName, saveCandidateName } from "../../utilities/utility";
const WaitingForTrainer = ({
  setStarted,
  linkCode,
  socket,
  ainfo,
  setAinfo,
  onGoLive,
}) => {
  const WAITING = "waiting";
  // Function to check the status
  async function checkStatus() {
    try {
      axios
        .post(
          API_ENDPOINT + "trainer/assessment-details/",
          { linkCode },
          HEADER_TOKEN
        )
        .then((res) => {
          let d = res.data;
          if (d.success) {
            let A = d.message;
            if (A.status === "started") {
              onGoLive();
            }
          }
        });
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  }

  // Poll the status every 5 seconds
  setInterval(checkStatus, 10000);
  const [name, setName] = useState();
  useEffect(() => {
    setName(getCandidateName()?.name || "");
  }, []);
  // useEffect(()=>{
  //     if(isCandidateExists()){
  //         let cn = getCandidateName()
  //          let d = {name: cn.name, status: WAITING, score: 0, linkCode: ainfo.linkCode, numAttempted: 0}
  //          socket.emit("joining", d)

  //          socket.on('start-'+linkCode, m=>{
  //             /*
  //                 START ASSESSMENT COMMAND
  //             */
  //            //document.location.href = '/assessment/live/'+linkCode
  //            onGoLive()
  //          })
  //     }
  // }, [])

  return (
    <div
      style={{
        background: "white",
        width: "100vw",
        height: "100vh",
        position: "fixed",
      }}
    >
      <IntroHeader ainfo={ainfo} />
      <div className="waiting-trainer">
        <div
          style={{
            flex: 0.5,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            {/* <center><img src={al} alt="Loader"/></center> */}
            <div alignSelf="center">
              Hey{" "}
              <TextField
                required
                value={name}
                onChange={(event) => {
                  setName(event.target.value); // Update the name state
                  saveCandidateName(event.target.value); // Save the name using this function
                }}
                variant="outlined"
                size="small"
                label="Name"
              />
            </div>
            <div
              className="animate-charcter"
              style={{
                margin: 20,
                color: "rgb(13 85 157)",
                fontSize: "32px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Thank you for your patience. Your assessment will begin shortly!
            </div>
          </div>
        </div>
        <div style={{ flex: 0.5, display: "flex", justifyContent: "center" }}>
          <div style={{ alignSelf: "center" }}>
            <img src={wft} alt="" style={{ width: "100%", maxHeight: "90%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WaitingForTrainer;
