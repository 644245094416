import React, { useState, useEffect, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import axios from "axios";
import { API_ENDPOINT, HEADER_TOKEN, MONTHS } from "../../constants/constants";
import { getHeader } from "../../utilities/utility";
// Register chart components
import { BarController, LineController } from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController, // Registering BarController
  LineController // Registering LineController
);

const AssessmentStatistics = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [dataA, setDataA] = useState([]);
  const [dataH, setDataH] = useState([]);
  const [pastYears, setPastYears] = useState([currentYear]);

  useEffect(() => {
    loadProfile();
  }, []);

  function loadProfile() {
    axios
      .get(API_ENDPOINT + "trainer/get-trainer-profile", getHeader())
      .then((res) => {
        let d = res.data;
        if (d.message && d.message.createdOn) {
          let sDate = new Date(d.message.createdOn);
          let sYear = sDate.getFullYear();
          let len = currentYear - sYear;
          let pYears = Array.from({ length: len }, (_, i) => currentYear - i);
          setPastYears(pYears);
        }
      });
  }

  useEffect(() => {
    getAssessmentOverview();
    getHeadCountsOverview();
  }, [selectedYear]);

  const getAssessmentOverview = useCallback(() => {
    axios
      .post(
        API_ENDPOINT + "trainer/assessment-overview",
        { year: selectedYear },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          let asA = [];
          let resA = d.message;
          MONTHS.map((m, i) => {
            let foundO = resA.find((r) => r._id === i + 1);
            asA.push(foundO ? foundO.numAssessments : 0);
          });
          setDataA(asA);
        }
      })
      .catch((err) => console.error("Error fetching assessment data", err));
  }, [selectedYear]);

  const getHeadCountsOverview = useCallback(() => {
    axios
      .post(
        API_ENDPOINT + "trainer/headcounts-overview",
        { year: selectedYear },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          let asA = [];
          let resA = d.message;
          MONTHS.map((m, i) => {
            let foundO = resA.find((r) => r._id === i + 1);
            asA.push(foundO ? foundO.count : 0);
          });
          setDataH(asA);
        }
      })
      .catch((err) => console.error("Error fetching headcount data", err));
  }, [selectedYear]);

  const data = {
    labels: MONTHS,
    datasets: [
      {
        label: "Candidates Count",
        data: dataH,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        type: "bar",
        yAxisID: "y-axis-candidates",
      },
      {
        label: "Assessment Count",
        data: dataA,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        type: "line",
        yAxisID: "y-axis-assessments",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      "y-axis-candidates": {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Candidates Count",
        },
      },
      "y-axis-assessments": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "Number of Assessments",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: false,
        text: `Assessment Statistics (${selectedYear})`,
      },
    },
  };

  console.log("data",data);
  console.log("options",options);

  const handleYearChange = (event) => {
    setSelectedYear(Number(event.target.value));
  };

  return (
    <div>
      {/* Inline layout for year selector and title */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* Title */}
        <h5 style={{ margin: 0, color: "#656a77" }}>
          Assessment Statistics {selectedYear}
        </h5>
        {/* Year Selector */}
        <div>
          <select
            id="yearSelector"
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              padding: "5px",
              fontSize: "16px",
              color: "#656a77",
              borderColor: "rgb(199 197 198)",
            }}
          >
            {pastYears &&
              pastYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Chart */}
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default AssessmentStatistics;
