import g1 from "../../assets/images/graph1.png";
import g2 from "../../assets/images/graph2.png";
import { Assessment } from "@mui/icons-material";
import AssessmentCard from "../widgets/assessmentCard";
import bannerImg from "../../assets/images/banner_img.jpg";
import AssessmentStatistics from "./AssessmentStatistics";
import { Box, Button, Grid, Paper } from "@mui/material";
import {
  deleteToken,
  getHeader,
  getMonth,
  getName,
  getPhone,
  getToken,
} from "../../utilities/utility";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT, HEADER_TOKEN, MONTHS } from "../../constants/constants";
import { CircularProgress } from "@mui/material";
import EmptyContent from "../widgets/emptyContent";
import {
  ShimmerButton,
  ShimmerContentBlock,
  ShimmerTable,
} from "react-shimmer-effects";
import DashCard from "../widgets/dashCard";
import { CircularProgressbar } from "react-circular-progressbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dashboard.css";

const Dashboard = ({ planData }) => {
  const sliderSettings = {
    dots: false, // Enables navigation dots
    infinite: true, // Disable infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of items to show
    slidesToScroll: 1, // Number of items to scroll per click
    arrows: true,
  };
  const [cCard, setCCard] = useState([]);
  const [todaysAssessmentLoaded, setTodaysAssessmentLoaded] = useState(false);
  const [randomImage, setRandomImage] = useState(bannerImg);
  const [subsCard, setSubsCard] = useState({
    remSubsDays: 0,
    remSubsDaysPerc: 0,
    remAss: 0,
    remAssPerc: 0,
    remTop: 0,
    remTopPerc: 0,
    questions: 0,
    topics: 0,
  });
  useEffect(() => {
    loadTodaysAssessment();
    checkUser();
    getRandomImage();
    loadCardData();
  }, []);

  function loadCardData() {
    axios
      .get(API_ENDPOINT + "trainer/get-card-data", getHeader())
      .then((res) => {
        let d = res.data;
        if (d.message) {
          setSubsCard(d.message);
          console.log(d.message, "d.message");
        }
      });
  }

  function getRandomImage() {
    axios
      .get(API_ENDPOINT + "trainer/random-images", HEADER_TOKEN)
      .then((res) => {
        let d = res.data;
        if (d.success) {
          console.log(d.message, "d.message");
          setRandomImage(
            d.message && d.message.random ? d.message.random : bannerImg
          );
        }
      });
  }

  function loadTodaysAssessment() {
    setTodaysAssessmentLoaded(false);
    axios
      .post(API_ENDPOINT + "trainer/todays-assessment", {}, HEADER_TOKEN)
      .then((res) => {
        const d = res.data;
        console.log(d,"load")
        setTodaysAssessmentLoaded(true);
        if (d.success) {
          setCCard(d.data && d.data.length > 0 ? d.data : "");
        } else {
          if (!d.auth) {
            deleteToken();
            document.location.href = "/login";
          }
        }
      });
  }
  function checkUser() {
    if (getName() === null || getPhone() === null) {
      deleteToken();
      document.location.href = "/login";
    }
    axios
      .post(API_ENDPOINT + "trainer/check-user", {}, HEADER_TOKEN)
      .then((res) => {
        let d = res.data;
        if (!d.success) {
          deleteToken();
          document.location.href = "/login";
        }
      });
  }
  const seeAll = () => {
    document.location.href = "/trainer/my-assessments";
  };
  return (
    <div>
      <div
        style={{
          fontSize: 24,
          fontFamily: "Nunito, sans-serif",
          fontWeight: 500,
          color: "#3208FF",
        }}
      >
        Hello {getName().trim().split(" ")[0]}
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: 1, fontSize: 16, color: "#6C7383" }}>
          Today's Assessment
        </div>
        <div
          onClick={seeAll}
          style={{
            marginRight: 40,
            fontWeight: 500,
            color: "#3208FF",
            cursor: "pointer",
          }}
        >
          See all
        </div>
      </div>
      <div style={{ marginRight: 38, marginBottom: "25px" }}>
        {todaysAssessmentLoaded ? (
          cCard && cCard.length === 1 ? (
            cCard.map((cCard, index) => (
              <AssessmentCard
                key={index}
                name={cCard.title.slice(0, 21)}
                num={cCard.numQns}
                duration={cCard.duration}
                code={cCard.code}
                linkCode={cCard.linkCode}
                show={true}
              />
            ))
          ) : cCard && cCard.length > 1 ? (
            <Slider {...sliderSettings}>
              {cCard.map((cCard, index) => (
                <AssessmentCard
                  key={index}
                  name={cCard.title.slice(0, 21)}
                  num={cCard.numQns}
                  duration={cCard.duration}
                  code={cCard.code}
                  linkCode={cCard.linkCode}
                  show={true}
                />
              ))}
            </Slider>
          ) : (
            <div
              style={{
                display: "flex",
                padding: 12,
                borderRadius: 8,
                color: "#ccc",
                fontWeight: 600,
                fontSize: 17,
                justifyContent: "center",
              }}
            >
              No Assessments for Today
            </div>
          )
        ) : (
          <ShimmerTable row={1} col={5} />
        )}
      </div>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6} md={3}>
          <DashCard
            bgColor="#fce8e8"
            title="Assessments"
            value={subsCard.remAssPerc}
            subvalue="Pending"
            //progress={subsCard.remAssPerc} // Example percentage
            progressColor="#f25c54"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashCard
            bgColor="#fff8e6"
            title="Top Up"
            value={subsCard.remTop}
            subvalue="Pending"
            // progress={subsCard.remTopPerc} // Example percentage
            progressColor="#f5a623"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashCard
            bgColor="#e8f8f0"
            title="Total Topics"
            value={subsCard.topics}
            subvalue=""
            progressColor="#32c48d" // Match progress color for the bottom border
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashCard
            bgColor="#f3f6fb"
            title="Total Questions"
            value={subsCard.questions}
            subvalue=""
            progressColor="#5865f2" // Match progress color for the bottom border
          />
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginTop: "24px",
          alignItems: "stretch",
          flexWrap: "wrap",
          marginBottom: "16px",
        }}
      >
        <div
          style={{
            flex: "2",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minWidth: "300px", // Min width to avoid shrinking
          }}
        >
          <AssessmentStatistics />
        </div>
        <div
          style={{
            flex: "1",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "column", // Stack items vertically
            justifyContent: "center",
            alignItems: "stretch", // Ensure child elements stretch horizontally
            minWidth: "300px", // Min width to avoid shrinking
          }}
        >
          <h5
            style={{
              marginBottom: "16px",
              color: "#656a77",
              textAlign: "left",
            }}
          >
            Today's Quote
          </h5>
          <img
            src={randomImage}
            alt="banner"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
